import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import router from './router'
import moment from 'moment-timezone'
import swal from 'sweetalert2'
import vSelect from "vue-select";
import './vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import 'es6-promise/auto'
import BackButton from './components/BackButton.vue';
import store from './store'
import { Table, TableColumn, Switch } from 'element-ui';
// import { initFacebookSdk } from './_helpers'
import ToggleButton from 'vue-js-toggle-button'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt, faSpinner, faChevronLeft, faChevronRight, faEdit, faPlus, faEye, faSearch, faTimes, faSave, faTrash, faExternalLinkAlt, faShoppingCart, faGamepad, faWallet, faUserSecret, faBars, faCircle, faUsers, faCashRegister, faCog, faImages, faClipboard, faInfoCircle, faImage, faFileAlt, faSearchPlus, faCreditCard, faEyeSlash, faArchive, faCopyright, faSort, faCubes, faCheck, faStar, faTruck, faFileExcel, faFilePdf, faTags, faBell, faUser, faUserCircle, faQuestion, faTint, faBorderStyle, faMedal, faClock, faChartLine, faSortUp, faSortDown, faSyncAlt, faInfo, faPencilAlt, faCalendar, faBroom, faKey, faRecycle, faBarcode } from '@fortawesome/free-solid-svg-icons'
// import config from '@/admin-config.json'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSignOutAlt, faSpinner, faChevronLeft, faChevronRight, faEdit, faPlus, faEye, faSearch, faTimes, faSave, faTrash, faExternalLinkAlt, faShoppingCart, faGamepad, faWallet, faUserSecret, faBars, faCircle, faUsers, faCashRegister, faCog, faImages, faImage, faClipboard, faInfoCircle, faFileAlt, faSearchPlus, faCreditCard, faEye, faEyeSlash, faArchive, faCopyright, faSort, faCubes, faCheck, faStar, faTruck, faFileExcel, faFilePdf, faTags, faBell, faUser, faUserCircle, faQuestion, faTint, faBorderStyle, faMedal, faClock, faChartLine, faSortUp, faSortDown, faSyncAlt, faInfo, faPencilAlt, faTrash, faCalendar, faBroom, faKey, faRecycle, faBarcode)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component("v-select", vSelect)
Vue.component('BackButton', BackButton)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
// Vue.use(Loading)
Vue.use(ToggleButton)

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)

moment.tz.setDefault('Asia/Jakarta')
moment.locale('id-ID')
// Vue.prototype.$webConfig = config
Vue.prototype.$moment = moment
Vue.prototype.$http = axios
Vue.prototype.$swal = swal
Vue.prototype.$formatPrice = (value) => {
  if (value === null) {
    return 0
  }
  return new Intl.NumberFormat('id-ID', {minimumFractionDigits: (value % 1 > 0 ? 2 : 0)}).format(value)
}

Vue.prototype.$capitalizeFirstLetter = (string) => {
  return string === null ? '' : string.charAt(0).toUpperCase() + string.slice(1);
}


Vue.config.productionTip = false

setTimeout(() => {
  new Vue({
    render: h => h(App),
    router,
    store
  }).$mount('#app')
}, 500)