import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import { Roles } from '@/_helpers';

const NotFound = () => import(/* webpackChunkName: "NotFound" */ '@/pages/GeneralViews/NotFoundPage')
const Forbidden = () => import(/* webpackChunkName: "NotFound" */ '@/pages/GeneralViews/ForbiddenPage')
const Login = () => import(/* webpackChunkName: "Login" */ '@/pages/Auth/Login.vue')
const Logout = () => import(/* webpackChunkName: "Logout" */ '@/pages/Auth/Logout.vue')
const AuthLayout = () => import(/* webpackChunkName: "GeneralScreenDashboard" */ '@/pages/Layouts/AuthLayout.vue')
const GeneralScreenDashboard = () => import(/* webpackChunkName: "GeneralScreenDashboard" */ '@/pages/Layouts/GeneralScreenDashboard.vue')
const EmptyContent = () => import(/* webpackChunkName: "GeneralScreenDashboard" */ '@/pages/Layouts/EmptyContent.vue')
const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Dashboard/index.vue')

const UserList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Users/List.vue')
const UserForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Users/Form.vue')
const UserDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Users/Detail.vue')

const CostumerList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Costumer/List.vue')
const CostumerForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Costumer/Form.vue')
const CostumerDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Costumer/Detail.vue')

const StockList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Stocks/List.vue')
const StockForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Stocks/Form.vue')
const StockDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Stocks/Detail.vue')

const BuyBackList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/BuyBack/List.vue')
const BuyBackForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/BuyBack/Form.vue')
const BuyBackDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/BuyBack/Detail.vue')

const CraftList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Craft/List.vue')
const CraftForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Craft/Form.vue')
const CraftDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Craft/Detail.vue')

const SaleList = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Sale/List.vue')
const SaleForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Sale/Form.vue')
const SaleDetail = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Sale/Detail.vue')

const SettingForm = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Setting/Form.vue')
const ScanInvoice = () => import(/* webpackChunkName: "Dashboard" */ '@/pages/ScanInvoice/index.vue')

const routes = [
    {
      path: '/auth',
      redirect: '/auth/login',
      component: AuthLayout,
      children: [
        {
          path: 'login',
          name: 'Login Account',
          component: Login
        }
      ],
      meta: { requireGuest: true }
    }, {
      path: '/logout',
      component: Logout,
      meta: { requireAuth: true }
    }, {
      path: '/',
      component: GeneralScreenDashboard,
      children: [
        {
          path: '/',
          name: 'Homepage',
          redirect: '/sales',
          // redirect: 'dashboard',
          meta: { requireAuth: true },
        }, {
          path: 'dashboard',
          // alias: '/',
          redirect: '/sales',
          name: 'Dashboard',
          component: Dashboard,
          meta: { requireAuth: true },
        }, {
          path: 'scan-invoice',
          name: 'Scan Invoice',
          component: ScanInvoice,
          meta: { requireAuth: true },
        }, {
          path: '/setting',
          name: 'Empty Content Setting Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Pengaturan Akun',
              component: SettingForm,
              meta: { requireAuth: true },
            }]
        }, {
          path: 'users',
          name: 'Empty Content Pengguna Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Listing Pengguna',
              component: UserList,
              meta: { title: 'Kelola Pengguna', neeedRole: [Roles.ADMIN] }
            },
            {
              path: 'create',
              name: 'Tambah Data Pengguna',
              component: UserForm,
              meta: { title: 'Tambah Pengguna', neeedRole: [Roles.ADMIN] }
            },
            {
              path: ':id',
              name: 'Detail Data Pengguna',
              component: UserDetail,
              meta: { title: 'Detail Pengguna', neeedRole: [Roles.ADMIN] }
            },
            {
              path: ':id/edit',
              name: 'Ubah Data Pengguna',
              component: UserForm,
              meta: { title: 'Ubah Pengguna', neeedRole: [Roles.ADMIN] }
            }
          ]
        }, {
          path: 'costumers',
          name: 'Empty Content Costumer Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Listing Costumer',
              component: CostumerList,
              meta: { title: 'Kelola Costumer' }
            },
            // {
            //   path: 'create',
            //   name: 'Tambah Data Costumer',
            //   component: CostumerForm,
            //   meta: { title: 'Tambah Costumer' }
            // },
            {
              path: ':id',
              name: 'Detail Data Costumer',
              component: CostumerDetail,
              meta: { title: 'Detail Costumer' }
            },
            {
              path: ':id/edit',
              name: 'Ubah Data Costumer',
              component: CostumerForm,
              meta: { title: 'Ubah Costumer' }
            }
          ]
        }, {
          path: 'stocks',
          name: 'Empty Content Stok Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Listing Stok',
              component: StockList,
              meta: { title: 'Kelola Stok' }
            },
            {
              path: 'create',
              name: 'Tambah Data Stok',
              component: StockForm,
              meta: { title: 'Tambah Stok' }
            },
            {
              path: ':id',
              name: 'Detail Data Stok',
              component: StockDetail,
              meta: { title: 'Detail Stok' }
            },
            {
              path: ':id/edit',
              name: 'Ubah Data Stok',
              component: StockForm,
              meta: { title: 'Ubah Stok' }
            }
          ]
        }, {
          path: 'buy-backs',
          name: 'Empty Content Buy Back Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Listing Buy Back',
              component: BuyBackList,
              meta: { title: 'Kelola Buy Back' }
            },
            {
              path: 'create',
              name: 'Tambah Data Buy Back',
              component: BuyBackForm,
              meta: { title: 'Tambah Buy Back' }
            },
            {
              path: ':id',
              name: 'Detail Data Buy Back',
              component: BuyBackDetail,
              meta: { title: 'Detail Buy Back' }
            },
            {
              path: ':id/edit',
              name: 'Ubah Data Buy Back',
              component: BuyBackForm,
              meta: { title: 'Ubah Buy Back' }
            }
          ]
        }, {
          path: 'crafts',
          name: 'Empty Content Craft Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Listing Craft',
              component: CraftList,
              meta: { title: 'Kelola Tempahan' }
            },
            {
              path: 'create',
              name: 'Tambah Data Craft',
              component: CraftForm,
              meta: { title: 'Tambah Tempahan' }
            },
            {
              path: ':id',
              name: 'Detail Data Craft',
              component: CraftDetail,
              meta: { title: 'Detail Tempahan' }
            },
            {
              path: ':id/edit',
              name: 'Ubah Data Craft',
              component: CraftForm,
              meta: { title: 'Ubah Tempahan' }
            }
          ]
        }, {
          path: 'sales',
          name: 'Empty Content Sales Container',
          component: EmptyContent,
          children: [
            {
              path: 'index',
              alias: '/',
              name: 'Listing Sales',
              component: SaleList,
              meta: { title: 'Kelola Penjualan' }
            },
            {
              path: 'create',
              name: 'Tambah Data Sales',
              component: SaleForm,
              meta: { title: 'Tambah Penjualan' }
            },
            {
              path: ':id',
              name: 'Detail Data Sales',
              component: SaleDetail,
              meta: { title: 'Detail Penjualan' }
            },
            {
              path: ':id/edit',
              name: 'Ubah Data Sales',
              component: SaleForm,
              meta: { title: 'Ubah Penjualan' }
            }
          ]
        }
      ],
      meta: { requireAuth: true }
    }, {
      path: '/403',
      component: Forbidden
    }, {
      path: '*', component: NotFound
    }
  ]
  
  Vue.use(VueRouter)
  const router = new VueRouter({
    routes, // short for router: router
    mode: 'history',
    linkActiveClass: 'active'
  })
  
  if (store.getters['account/isLoggedIn']) {
    store.dispatch('account/saveTokens', {tokens: store.getters['account/tokens'], accountData: null, isLocalLogin: true})
  }
  
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.neeedRole)) {
      if (!store.getters['account/isLoggedIn']) {
        next({
          path: '/auth/login',
          query: { redirect: to.fullPath }
        })
      }
      if (to.meta.neeedRole.includes(store.getters['account/accountData'].role)) {
        next()
      } else {
        next({
          path: '/403'
        })
      }
    } else if (to.matched.some(record => record.meta.requireAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters['account/isLoggedIn']) {
        next({
          path: '/auth/login',
          query: { redirect: to.fullPath }
        })
      } else if (to.matched.some(record => record.meta.needPermission)) {
        const needPermission = typeof to.meta.needPermission === 'function' ? to.meta.needPermission(to) : to.meta.needPermission
        if (typeof to.meta.needPermission !== 'undefined' && store.getters['account/needPermission'](needPermission)) {
          next()
        } else {
          next({
            path: '/403'
          })
        }
      } else {
        next()
      }
    } else if (to.matched.some(record => record.meta.requireGuest)) {
      if (store.getters['account/isLoggedIn']) {
        next({
          path: '/',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    }  else {
      next()
    }
    window.scrollTo(0, 0)
  })
  
  export default router